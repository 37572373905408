export default class Language {
  constructor(language, months, monthsAbbr, days) {
    this.language = language;
    this.months = months;
    this.monthsAbbr = monthsAbbr;
    this.days = days;
    this.rtl = false;
    this.ymd = false;
    this.yearSuffix = "";
  }

  get language() {
    return this._language;
  }

  set language(language) {
    if (typeof language !== "string") {
      throw new TypeError("Language must be a string");
    }
    this._language = language;
  }

  get months() {
    return this._months;
  }

  set months(months) {
    if (months.length !== 12) {
      throw new RangeError(
        `There must be 12 months for ${this.language} language`
      );
    }
    this._months = months;
  }

  get monthsAbbr() {
    return this._monthsAbbr;
  }

  set monthsAbbr(monthsAbbr) {
    if (monthsAbbr.length !== 12) {
      throw new RangeError(
        `There must be 12 abbreviated months for ${this.language} language`
      );
    }
    this._monthsAbbr = monthsAbbr;
  }

  get days() {
    return this._days;
  }

  set days(days) {
    if (days.length !== 7) {
      throw new RangeError(
        `There must be 7 days for ${this.language} language`
      );
    }
    this._days = days;
  }
}
