import QueryUtils from "../queryUtils";

const queries = [
  // Read
  `query GetAbsences( $uuid: ID!, $cursor: String ) {
    proclamateur( id: $uuid ) {

      absences( after: $cursor ) {
        edges {
          cursor

          node {
            dateDebut
            dateFin
            uuid
          }
        }
      }
    }
  }`,
  // Update
  `mutation CreateAbsence( $entity: createAbsenceInput! ) {
    createAbsence( input: $entity ) {
      absence {
        uuid
      }
    }
  }`,
  // Delete
  `mutation DeleteAbsence( $entity: deleteAbsenceInput! ) {
    deleteAbsence( input: $entity ) {
      absence {
        id
      }
    }
  }`,
];

export default new QueryUtils(queries);
