<template>
  <div class="envelope">
    <div class="header-envelope">
      <div class="ticket-envelope">
        <br />
        <div>
          Absent du {{ getDateAbsence(absence.start) }} au
          {{ getDateAbsence(absence.end) }}
        </div>
      </div>
      <div class="row justify-content-center">
        <button
          class="btn btn-danger btn-md center-block button-vue-alone"
          v-on:click="deleteAbsence"
          :disabled="isDelete"
        >
          <icon
            class="wait"
            name="spinner"
            spin
            v-bind:class="[isDelete ? '' : 'hide']"
          />
          Supprimer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-awesome/icons";
import Icon from "vue-awesome/components/Icon";
import DateUtils from "../utils/dateUtils";

export default {
  name: "AbsenceItem",

  data() {
    return {
      absence: {
        uuid: this.item.node.uuid,
        start: this.item.node.dateDebut,
        end: this.item.node.dateFin,
      },
      isDelete: false,
    };
  },

  props: {
    item: Object,
  },

  components: {
    Icon,
  },

  methods: {
    deleteAbsence: function () {
      this.isDelete = true;
      this.$emit("onDelete", this, this.absence.uuid);
    },

    clear: function () {
      this.isDelete = false;
    },

    getDateAbsence: function (dateDay) {
      return new DateUtils(dateDay).getDateFormat();
    },
  },
};
</script>
