<template>
  <div class="vue">
    <div class="container-vue container">
      <h1>Absences</h1>
      <div class="form-absence">
        <h3>Ajouter une nouvelle période d'absence</h3>
        <div class="container-datepicker">
          <datepicker
            class="datepicker"
            placeholder="Date de début"
            v-model="dateDebut"
            :disabledDates="stateDebut.disabledDates"
            :format="getDatepicker"
            :language="languages.fr"
            @selected="selectDateDebut"
            @closed="closeDateDebut"
          />
          <span class="datepicker middle">Au</span>
          <datepicker
            class="datepicker"
            placeholder="Date de fin"
            v-model="dateFin"
            :disabledDates="stateFin.disabledDates"
            :format="getDatepicker"
            :language="languages.fr"
            @selected="selectDateFin"
          />
        </div>
        <div class="errorMessage" v-if="isError">
          <p>
            La date de début et de fin sont obligatoire pour l'enregistrement.
          </p>
        </div>
        <div class="row justify-content-center">
          <button
            class="btn btn-success btn-md center-block button-vue-alone"
            v-on:click="onSave"
            :disabled="isSave"
          >
            <icon
              class="wait"
              name="spinner"
              spin
              v-bind:class="[isSave ? '' : 'hide']"
            />
            Enregistrer
          </button>
        </div>
      </div>
      <transition-group name="list-complete">
        <div
          class="list-complete-item"
          v-for="absence in absences"
          :key="absence.node.uuid"
        >
          <item v-bind:item="absence" v-on:onDelete="onDeleteAbsence" />
        </div>
      </transition-group>
      <infinite-loading
        @infinite="getAbsences"
        spinner="spiral"
        ref="infiniteLoading"
      >
        <template v-slot:no-more>
          <span></span>
        </template>
        <template v-slot:no-results>
          <div class="message no-result">
            <h2>Pas d'absences.</h2>
          </div>
        </template>
        <template v-slot:error>
          <div class="message">
            <h2>Opps, something went wrong.</h2>
          </div>
        </template>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import "vue-awesome/icons";
import Icon from "vue-awesome/components/Icon";
import Datepicker from "vuejs-datepicker";
import * as lang from "../locale/index.js";
import { uuid } from "vue-uuid";
import DateUtils from "../utils/dateUtils";
import Graphql from "../utils/graphQl";
import QueryUtils from "../utils/queries/absenceQuery";
import Item from "@/components/AbsenceItem";
import QueryMixin from "../utils/queryMixin";
import RedirectMixin from "../utils/redirectionMixin";

export default {
  name: "Absence",

  mixins: [QueryMixin, RedirectMixin],

  title() {
    return `${this.title} - ${this.congregationName}`;
  },

  data() {
    return {
      absences: [],
      cursorAbsence: "",
      isSave: false,
      isError: false,
      dateDebut: null,
      dateFin: null,
      stateDebut: {
        disabledDates: {
          to: new Date(),
        },
      },
      stateFin: {
        disabledDates: {
          to: new Date(),
        },
      },
      languages: lang,
      language: "fr",
      title: this.$store.state.title,
      token: this.$store.state.token,
      userUuid: this.$store.state.userUuid,
      congregationName: this.$store.state.congregationName,
    };
  },

  components: {
    Datepicker,
    Icon,
    Item,
  },

  watch: {
    absences: function (value) {
      if (value.length === 0) {
        this.$refs.infiniteLoading.stateChanger.reset();
        this.$refs.infiniteLoading.stateChanger.complete();
      } else if (value.length === 1) {
        this.$refs.infiniteLoading.stateChanger.reset();
        this.$refs.infiniteLoading.stateChanger.loaded();
        this.$refs.infiniteLoading.stateChanger.complete();
      }
    },
  },

  methods: {
    getAbsences: function ($state) {
      QueryUtils.setRead();
      QueryUtils.setVariables(
        this.getQueryVariables(
          "uuid",
          "proclamateurs/",
          this.userUuid,
          this.cursorAbsence
        )
      );

      // Start GraphQL - Return Absence
      new Graphql(this.token, QueryUtils).request().then((response) => {
        let result = response.data.data.proclamateur.absences.edges;

        if (result.length > 0) {
          this.cursorAbsence = result[result.length - 1].cursor;
          this.absences.push(...result);

          $state.loaded();
        } else {
          $state.complete();
        }
      });
      // End GraphQL
    },

    selectDateDebut: function () {
      this.isError = false;
    },

    selectDateFin: function () {
      this.isError = false;
    },

    closeDateDebut: function () {
      this.stateFin = {
        disabledDates: {
          to: this.dateDebut,
        },
      };
    },

    onSave: function () {
      if (this.dateDebut !== null && this.dateFin !== null) {
        this.isSave = true;

        QueryUtils.setUpdate();
        QueryUtils.setVariables(this.setMutableVariables());

        // Start GraphQL - Mutation Absence
        new Graphql(this.token, QueryUtils).request().then((response) => {
          this.isSave = false;

          this.absences.push({
            node: {
              dateDebut: this.dateDebut,
              dateFin: this.dateFin,
              uuid: response.data.data.createAbsence.absence.uuid,
            },
          });

          this.dateDebut = null;
          this.dateFin = null;
        });
        // End GraphQL
      } else {
        this.isError = true;
      }
    },

    onDeleteAbsence: function (absenceItem, uuid) {
      QueryUtils.setDelete();
      QueryUtils.setVariables(this.setDeleteVariables(uuid));

      // Start GraphQL - Delete Absence
      new Graphql(this.token, QueryUtils).request().then((response) => {
        if (
          response.data.data.deleteAbsence.absence.id ===
          "absences/" + uuid
        ) {
          absenceItem.clear();

          let indice = this.absences
            .map(function (element) {
              return element.node.uuid;
            })
            .indexOf(uuid);

          if (indice !== -1) {
            this.absences.splice(indice, 1);
          }
        }
      });
      // End GraphQL
    },

    getDatepicker: function (date) {
      return new DateUtils(date).getDateFormat();
    },

    setMutableVariables: function () {
      let uuidAbsence = uuid.v4();

      let variables = {
        _id: 0,
        dateDebut: this.dateDebut,
        dateFin: this.dateFin,
        uuid: uuidAbsence,
        proclamateur: "proclamateurs/" + this.userUuid,
      };

      return this.getMutableContainer(variables, uuidAbsence);
    },

    setDeleteVariables: function (uuid) {
      let variables = {
        id: "absences/" + uuid,
      };

      return this.getDeleteContainer(variables, uuid);
    },
  },
};
</script>
